import { useAuth } from '@/use/useAuth';
import {
  postTrackingAction,
  getTrackingAction,
} from '../modules/together-x/api/api';

// eslint-disable-next-line
export const useTracking = () => {
  const { headers } = useAuth();

  const trackAction = (action: string) =>
    postTrackingAction(action)(headers.value);

  const getTrackActionStatus = (action: string) =>
    getTrackingAction(action)(headers.value);

  return { trackAction, getTrackActionStatus };
};
