/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { authApiGet, authApiPost } from '@/api/api.js';
import { PostApplicationDTO, PostSelectionsDTO, PostVotingDTO } from '../types';

export const URLS = {
  DELETE_APPLICATION: 'togetherx/application/delete',
  DELETE_VIDEO: 'togetherx/video/delete',
  GET_APPLICATION: 'togetherx/application/get',
  GET_ARCHIVE_GRID: 'togetherx/documentation/archive',
  GET_ARTIST_APPLICANTS: 'togetherx/application/get_all_applicants',
  GET_ARTIST_SELECTED_APPLICANTS:
    'togetherx/application/get_selected_applicants',
  GET_VOTING: 'togetherx/voting',
  POST_VOTING: 'togetherx/voting',
  GET_SELECTIONS: 'togetherx/documentation/selections',
  POST_SELECTIONS: 'togetherx/documentation/selections',
  GET_PHOTO_URL: 'togetherx/photo',
  GET_INFOS: 'togetherx/infos',
  GET_VIDEO_URL: 'togetherx/video',
  POST_APPLICATION: 'togetherx/application',
  POST_TRACKING_ACTION: 'action/',
  POST_VIDEO_FRAGMENT: '/togetherx/documentation/stories',
  SET_REMINDER_EMAIL: 'togetherx/reminder/email',
  SET_REMINDER_SMS: 'togetherx/reminder/sms',
  SET_SUPPORT_ARTIST_TEAM: 'togetherx/support_artist_team',
  GET_DOCUMENTATION_CAROUSEL: 'togetherx/documentation',
  GET_TOKYO_FEED: 'togx-tokio-ugc/feed',
  GET_TOKYO_PHOTO_URL: 'togx-tokio-ugc/upload/photo',
  GET_TOKYO_VIDEO_URL: 'togx-tokio-ugc/upload/video',
};

export const getApplication = authApiGet(URLS.GET_APPLICATION);

export const postApplication = (data: PostApplicationDTO) =>
  authApiPost(URLS.POST_APPLICATION, data);

export const deleteApplication = authApiPost(URLS.DELETE_APPLICATION);

export const getInfos = authApiGet(URLS.GET_INFOS);

export const setReminderEmail = authApiPost(URLS.SET_REMINDER_EMAIL);

export const setReminderSMS = authApiPost(URLS.SET_REMINDER_SMS);

export const setSupportArtistTeam = (artistTeam: string) =>
  authApiPost(URLS.SET_SUPPORT_ARTIST_TEAM, { artist_team: artistTeam });

export const getImageUrl = authApiGet(URLS.GET_PHOTO_URL);

export const getArchiveGrid = (artistNormalisedName) =>
  authApiGet(`${URLS.GET_ARCHIVE_GRID}/${artistNormalisedName}`);

export const getArtistApplicants = (artistNormalisedName) =>
  authApiGet(`${URLS.GET_ARTIST_APPLICANTS}/${artistNormalisedName}`);

export const getArtistSelectedApplicants = (artistNormalisedName) =>
  authApiGet(`${URLS.GET_ARTIST_SELECTED_APPLICANTS}/${artistNormalisedName}`);

export const getAllApplicants = authApiGet(URLS.GET_ARTIST_APPLICANTS);

export const getDocumentationCarousel = authApiGet(
  URLS.GET_DOCUMENTATION_CAROUSEL
);

export const getVideoUrl = authApiGet(URLS.GET_VIDEO_URL);

export const deleteVideo = authApiGet(URLS.DELETE_VIDEO);

export const getTrackingAction = (actionName: string) =>
  authApiGet(URLS.POST_TRACKING_ACTION + actionName);

export const postTrackingAction = (actionName: string) =>
  authApiPost(URLS.POST_TRACKING_ACTION + actionName);

export const getVoting = authApiGet(URLS.GET_VOTING);
export const postVoting = (data: PostVotingDTO) =>
  authApiPost(URLS.POST_VOTING, data);

export const getSelections = authApiGet(URLS.GET_SELECTIONS);
export const postSelections = (data: PostSelectionsDTO) =>
  authApiPost(`${URLS.POST_SELECTIONS}/${data.category_id}/${data.item_id}`, {
    selection_id: data.selection_id,
  });

export const postVideoFragment = (config: {
  videoId: string;
  categoryId: string;
  percent: number;
}) =>
  authApiPost(
    `${URLS.POST_VIDEO_FRAGMENT}/${config.categoryId}/${config.videoId}`,
    {
      progress_percent: config.percent,
    }
  );

export const getTokyoFeed = ({ limit }: { limit: number }) =>
  authApiGet(URLS.GET_TOKYO_FEED + `?limit=${limit || 20}`);

export const getTokyoPhotoUrl = authApiGet(URLS.GET_TOKYO_PHOTO_URL);
export const getTokyoPhotoUrlForFeed = (feedId: string) =>
  authApiGet(URLS.GET_TOKYO_PHOTO_URL + '/' + feedId);

export const getTokyoVideoUrl = authApiGet(URLS.GET_TOKYO_VIDEO_URL);
export const getTokyoVideoUrlForFeed = (feedId: string) =>
  authApiGet(URLS.GET_TOKYO_VIDEO_URL + '/' + feedId);
