
import { defineComponent, computed, reactive } from 'vue';
import useTailwind from '@/use/useTailwind';
import { ResponsiveImageSet } from '@/types';
import { ImageWithSources } from '@/types';

export default defineComponent({
  props: {
    responsiveImageSet: {
      type: Object as () => ResponsiveImageSet | ImageWithSources,
      required: true,
    },
    imageClasses: {
      default: 'object-cover w-full h-full',
      type: String,
    },
    darken: {
      type: Boolean,
      default: false,
    },
    lightDarkOverlay: {
      type: Boolean,
      default: false,
    },
    lightOverlay: {
      type: Boolean,
      default: false,
    },
    blurOverlay: {
      type: Boolean,
      default: false,
    },
    turquoiseOverlay: {
      type: Boolean,
      default: null,
    },
    overlayOpacityValue: {
      type: Number,
      default: null,
    },
    overlayHeight: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const mobile = reactive(
      props.responsiveImageSet?.mobile || props.responsiveImageSet?.desktop
    );
    const desktop = reactive(
      props.responsiveImageSet?.desktop || props.responsiveImageSet?.mobile
    );

    /*
      Can't destructure mdBreakpoint with object dot property accessor (`useTailwind().screens.md`)
        because `screens` can be not defined.
        Otherwise we get a [E] Property 'md' does not exist on type 'TailwindThemeValue'
      More info in the types source code:
        https://github.com/DefinitelyTyped/DefinitelyTyped/blob/9b9cd671114a2a5178809798d8e7f4d8ca6c2671/types/tailwindcss/tailwind-config.d.ts#L276
    */
    const mdBreakpoint = useTailwind().theme['screens']['md'];

    const mobileSrcset = computed(
      () => `
      ${mobile?.x1 || mobile?.x2 || mobile?.x3},
      ${mobile?.x2 || mobile?.x3 || mobile?.x1} 2x,
      ${mobile?.x3 || mobile?.x2 || mobile?.x1} 3x
    `
    );

    const desktopSrcset = computed(
      () => `
      ${desktop?.x1 || desktop?.x2 || desktop?.x3},
      ${desktop?.x2 || desktop?.x3 || desktop?.x1} 2x,
      ${desktop?.x3 || desktop?.x2 || desktop?.x1} 3x
    `
    );

    return {
      mdBreakpoint,
      mobileSrcset,
      desktopSrcset,
    };
  },
});
