import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["media", "srcset"]
const _hoisted_2 = ["src", "srcset", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", {
    class: _normalizeClass(["responsive-image", [{ 'responsive-image__blur-overlay': _ctx.blurOverlay }]]),
    style: _normalizeStyle(_ctx.$attrs?.style)
  }, [
    _createElementVNode("source", {
      media: `(min-width:${_ctx.mdBreakpoint})`,
      srcset: _ctx.desktopSrcset
    }, null, 8, _hoisted_1),
    _createElementVNode("img", {
      class: _normalizeClass(_ctx.imageClasses),
      src: _ctx.responsiveImageSet?.mobile.x1,
      srcset: _ctx.mobileSrcset,
      alt: _ctx.responsiveImageSet?.alt
    }, null, 10, _hoisted_2),
    (_ctx.darken)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (
        _ctx.lightDarkOverlay ||
        _ctx.lightOverlay ||
        _ctx.turquoiseOverlay ||
        _ctx.overlayOpacityValue !== null
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["absolute bottom-0 left-0 w-full h-full", [
        {
          'responsive-image__light-dark-overlay': _ctx.lightDarkOverlay,
          'responsive-image__light-overlay': _ctx.lightOverlay,
          'responsive-image__custom-value-overlay': _ctx.overlayOpacityValue,
          'responsive-image__turquoise-overlay': _ctx.turquoiseOverlay,
        },
      ]]),
          style: _normalizeStyle({
        opacity: `${_ctx.overlayOpacityValue !== null ? _ctx.overlayOpacityValue : 100}%`,
        maxHeight: _ctx.overlayHeight,
      })
        }, null, 6))
      : _createCommentVNode("", true)
  ], 6))
}